<template>
  <div>
    <b-table
      ref="table"
      :items="getAttendees"
      :fields="fields"
      responsive
      show-empty
    />
  </div>
</template>
<script>
import communityEvent from '@/common/compositions/CommunityEvents/communityEventApi'

export default {
  name: 'CommunityEventAttendees',
  computed: {
    fields() {
      return [
        'userId', 'firstName', 'lastName', 'email', { key: 'markedAt', label: 'Mark Attending Date Time' },
      ]
    },
  },
  setup() {
    const { listAttendees } = communityEvent()
    return { listAttendees }
  },
  methods: {
    getAttendees() {
      return this.listAttendees(this.$route.params.id).then(res => res.data.data)
    },
  },
}
</script>
<style lang="">

</style>
